import type { BasePageContentAPI } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { BLOCKS } from '@contentful/rich-text-types'

const HOME_FALLBACK_DATA: BasePageContentAPI = {
  id: '3WflFt0AhP0k5agSAfqagd',
  name: 'home-duplicated',
  blocks: [
    {
      id: '7cZTgCr5PxdQnYDOGPSy07',
      type: 'Carousel',
      props: {
        technicalName: 'Carousel - NETHERLANDS',
        slides: [
          {
            id: '6TWVODAJdDhdYfmGaHjZXy',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2KMQNkjJLim5Mn8NP48c7m/4898dca1a319b646b8b36829fac90698/GENERIC_HPBANNERS_iPhone13_Desktop_NL.jpeg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/7kLC9QFIpsmXaY2eq6TS0p/a72981b73bdc4f72e941591b4b1a4d20/GENERIC_HPBANNERS_iPhone13_Mobile_NL.jpeg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'NL - iPhone 13 x Generic',
            },
            link: {
              href: 'https://www.backmarket.nl/nl-nl/l/iphone-13/14c5d644-a43a-42a8-b8f3-37d5af3936fc',
            },
          },
          {
            id: '6sBn06HlfJHJvKRO5VrDqA',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/7lBzTt8isfgOdRZzh6gzAD/e129e3f7ca059b28b0b1a8f2646fd436/Good_Deals_HP_Desktop_NL.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/15z73hUrJwQvjdEq68aloq/110c8762085761e90b2e2dcced66cf0c/Good_Deals_HP_Mobile_NL.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'NL - Good Deals ',
            },
            link: {
              href: 'https://www.backmarket.nl/nl-nl/e/good-deals',
            },
          },
          {
            id: '1rW41tgVWHtaFULFKNZJJn',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5ZgEevabrzXWv0yYn5O5nI/f2f12b5e76f0bd1d948254da1527cf01/desktop-NL__1_.jpeg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/7GlESkaD71owcX2xnJqrTU/ae1bf38ac6384428456b9314d4365712/mobile-NL__1_.jpeg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'NL - BackUp',
            },
            link: {
              href: 'https://www.backmarket.nl/nl-nl/e/backup',
            },
          },
          {
            id: 'Z2Yu0mKM2b9vYx6ITufAj',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/imUm6vFiRPuExx3PuvtEV/80b915fd1dcaf88de41142e49cc0a9ac/Copy_of_VISION_Desktop_NL.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/2qaYQ3Oy3ykqTgaOzxnBEf/ecfc2e8a0c2c892843a11cf91bec6d77/Copy_of_VISION_Mobile_NL.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'NL - Vision',
            },
            link: {
              href: 'https://www.backmarket.nl/nl-nl/about-us',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '1fGWjqR43b5BoP4YPm3f4o',
      type: 'Categories',
      props: {
        technicalName: 'Onze populairste producten',
        title: 'Onze populairste producten',
        subtitle: 'Net zo populair als bitterballen',
        categories: [
          {
            id: '1WWdqxnxA9i4NeNL77sXj9',
            title: 'Smartphones',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/13PFF8Xa3s6EiayLPC97g2/9477b967cc653827d751bfb55bc16c52/iphone_XR.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'US - iPhone XR',
            },
            tagline:
              'Volgens een recente studie zijn refurbished smartphones ook slimmer.',
            link: {
              href: 'https://www.backmarket.nl/nl-nl/l/smartphones/6c290010-c0c2-47a4-b68a-ac2ec2b64dca',
            },
          },
          {
            id: '7zFZBtXeYhpB37eIDwQiLv',
            title: 'AirPods',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6UBdFdF7X6S0nGCexTB9pw/4cfedada6bae9fc32b9151aa7a3df95b/308532_7b427385-34fc-40f5-9e68-73890ed77684-thumb.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'AirPods & Earphones',
            },
            tagline: 'De laatste trend in oordopjes.',
            link: {
              href: 'https://www.backmarket.nl/nl-nl/l/airpods/5091ca6b-c36c-4d50-b644-fb3c5bdd5f16',
            },
          },
          {
            id: 'tszTeWNjCAf5O8GaxZO8w',
            title: 'iPhone',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6G8NfbciL3rtpnVeXho8BD/bf918f5935800eb9bb15d4609110cabb/290045_d7327841-d981-4566-b4a5-a392912a824f-thumb.jpg',
              width: 400,
              height: 400,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5opbjQ8sRrg3OeN3HI3kI3/069d4f5fbfe9949680301287d498cd65/290045_d7327841-d981-4566-b4a5-a392912a824f-thumb.jpg',
              widthMobile: 200,
              heightMobile: 200,
              alt: 'iPhone 11 Pro',
            },
            tagline: 'Geweldige deals voor jou én het milieu.',
            link: {
              href: 'https://www.backmarket.nl/nl-nl/l/iphone/aabc736a-cb66-4ac0-a3b7-0f449781ed39',
            },
          },
          {
            id: '6OOrsoLtze0LFunjlJVeXX',
            title: 'Tablets',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/65705ElrSjWTRPeYbI5ndC/22644ae5aff08909332541074c6c1651/310473_469892bf-a21c-4147-9515-450372c9dcb4.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPad 7',
            },
            tagline:
              'Tot 40% korting, dus je houdt nog geld over voor je Netflix-abonnement.',
            link: {
              href: 'https://www.backmarket.nl/nl-nl/l/tablets/5a3cfa21-b588-49b1-b4e9-2636bec68ada',
            },
          },
          {
            id: '6VmIvXbLPDBqxb3aoAAGoO',
            title: 'Laptops',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5wRFyaf2nEBVlUHR5SZuq2/db0737f22ed75e38519b18194f9fd827/Chromebooks___Laptops.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Laptop Windows',
            },
            tagline: 'Voor wanneer je mobiele scherm te klein wordt.',
            link: {
              href: 'https://www.backmarket.nl/nl-nl/l/laptop/630dab14-5051-49b9-bc7b-bb20876d4850',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '6hsoJJMT8XCdG8CTO6YNnU',
      type: 'Categories',
      props: {
        title: 'Andere categorieën',
        subtitle: 'Alles kan refurbished worden, zelfs je stofzuiger',
        categories: [
          {
            id: '4Y19v4A8zyVOiPzGjE3BgC',
            title: 'MacBook',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6MZVE5kh4OulMTCQlbTuT8/4089058e75b2bb2b2c5ef17aa4cb742d/MacBook_Pro_Touchbar.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'MacBook Pro TouchBar',
            },
            tagline: 'Beter dan de Apple Store.',
            link: {
              href: 'https://www.backmarket.nl/nl-nl/l/macbook-qwerty/50677fe5-b7d2-4f31-b8ed-824cb3bfd345',
            },
          },
          {
            id: 'DGnBV91bw7n9JoNhBrIxX',
            title: 'Samsung Galaxy',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/c3phFqsPsCEl7dJiGDX5d/6249079ce9bf03001aa4c601d0c8333f/S20STORE.png',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Samsung s20',
            },
            tagline: 'Premium smartphones zonder het premium prijskaartje.',
            link: {
              href: 'https://www.backmarket.nl/nl-nl/l/samsung-smartphone/99760870-ed75-482f-a626-2b4f964c55ae',
            },
          },
          {
            id: '4xso7vuUgCj3U25BLwV8IA',
            title: 'Consoles',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/27egcHU8De6FntEVyP9od5/0e4ff9091e18b3d1b5a2b55bfac54cb9/1584620697.1677275.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'PS4',
            },
            tagline: 'Van A voor Atari tot X voor Xbox.',
            link: {
              href: 'https://www.backmarket.nl/nl-nl/l/consoles-en-games/0a092eda-8a90-4b60-85bd-8a3123b2fd84',
            },
          },
          {
            id: 'FDUZeFoDCVibXOQK2ZpCz',
            title: 'Apple Watch',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2coRI1LOw46qbxuVTi3bJY/28773d620d07fb4727dd5ac35deb729f/AppleWatch6.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Apple Watch',
            },
            tagline: 'Youtube kijken op een heel, heel, heel klein scherm.',
            link: {
              href: 'https://www.backmarket.nl/nl-nl/l/apple-watch/92caf545-c033-409f-bcb0-d3cfec8af49d',
            },
          },
          {
            id: '1PPVd4F0XIxAPqwrq06OZ7',
            title: 'Thuiswerken',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/7dk0E3AVXyqBrSbEObwCpC/e3659099f33e0d568e474b467c0541ac/Magic_Keyboard.jpeg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Magic Keyboard',
            },
            tagline:
              'The best of both worlds: thuiswerken op de allerbeste apparaten.',
            link: {
              href: 'https://www.backmarket.nl/nl-nl/l/thuiswerken/31b40fde-5c63-4773-83fa-0849a93f9cd3',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '4jpM9mB2XPLD0PQVU2iWsk',
      type: 'PressCarousel',
      props: {
        technicalName: 'NL - Press Carousel',
        subtitle: '(En zeggen leuke dingen)',
        title: 'Mensen praten over ons!',
        articles: [
          {
            id: '6R01vjgBkh83kQdULrjIDU',
            text: 'Waar je aan moet denken voordat je een gebruikte smartphone koopt',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/NtEqBzbGytB0fDyo0uf3b/b8651cdb8b3099afce2d9d15e65eea4a/1200px-Wired_logo.svg.png',
              width: 1200,
              height: 480,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/NtEqBzbGytB0fDyo0uf3b/b8651cdb8b3099afce2d9d15e65eea4a/1200px-Wired_logo.svg.png',
              widthMobile: 1200,
              heightMobile: 480,
              alt: 'Wired',
            },
            link: {
              href: 'https://www.wired.com/story/how-to-buy-a-used-phone/',
            },
            publicationDate: '05/2018',
          },
          {
            id: '71qGPQXjfmHHh3ive0UfGo',
            text: 'Back Market haalt $ 335 miljoen op om de refurbished techmarkt te laten groeien',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4HGEQI2k9nVoZ8qWTBfd3B/36f378036915985113463e28e9dcd441/forbes.jpg',
              width: 650,
              height: 455,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4HGEQI2k9nVoZ8qWTBfd3B/36f378036915985113463e28e9dcd441/forbes.jpg',
              widthMobile: 650,
              heightMobile: 455,
              alt: 'Forbes',
            },
            link: {
              href: 'https://www.forbes.com/sites/iainmartin/2021/05/18/back-market-raises-335-million-to-grow-refurbished-gadget-marketplace/?sh=3d8a781a56d6',
            },
            publicationDate: '05/2021',
          },
          {
            id: '5NuuMuSs7VIUsqCUYnnlgl',
            text: 'Moet je een tweedehands smartphone kopen?',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6nEleXCKAMwP8Jr5xFzYkc/1bd9bc7a1061edbbbfc1ddae25775b0d/BBC_logo__1997-2021_.svg.png',
              width: 1200,
              height: 342,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/6nEleXCKAMwP8Jr5xFzYkc/1bd9bc7a1061edbbbfc1ddae25775b0d/BBC_logo__1997-2021_.svg.png',
              widthMobile: 1200,
              heightMobile: 342,
              alt: 'BBC',
            },
            link: {
              href: 'https://www.bbc.com/news/business-54160851',
            },
            publicationDate: '10/2020',
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '47gHCDRranmSpAPsAZSlvx',
      type: 'Faq',
      props: {
        title: 'Nog iets anders?',
        subtitle: 'De 3 vragen die ons het meest gesteld worden',
        questions: [
          {
            id: '2VVMVvueXeEjaruw8ZUAOU',
            title:
              'Hoe kan ik weten of mijn toestel er niet binnen een maand mee ophoudt?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Van een pre-list screening proces voor alle verkopers tot "mystery orders" geplaatst door het technische personeel van Back Market (onder geheime codenamen 🤫); wij geven 110% om ervoor te zorgen dat de tech die we je aanbieden net zo goed is als we beloven. Bovendien wordt elk toestel geleverd met 30 dagen bedenktijd en 1 jaar garantie.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '3UYNhpElczedn6F7MgDCZR',
            title: 'Wat is het verschil tussen refurbished en nieuw?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Aan de buitenkant ziet een refurbished smartphone eruit (en werkt hij) als nieuw. Maar het is de binnenkant die echt telt. Refurbished tech - smartphones, tablets, laptops en computers - hebben veel minder impact op het milieu dan gloednieuwe.\n\n',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '3MbHCOH5qk4T9ivjSjYFup',
            title: 'Wacht even, wat verkopen jullie nog meer?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Je kunt de planeet ook op andere manieren helpen, en als het op tech aankomt, zijn we veel meer dan een oppervlakkige smartphone met een knap gezichtje. Van gamesystemen tot krultangen, of zelfs babyfoons en espressomachines (hallo, nieuwe ouders), we hebben waarschijnlijk de spullen waar jij naar op zoek bent.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
        rendering: 'HTML_ONLY',
        ctaLink: {
          link: {
            href: 'https://www.backmarket.nl/nl-nl/help',
          },
          label: 'Vind antwoorden',
        },
      },
      hiddenDevices: [],
    },
  ],
  type: 'core',
  title: 'Home',
  meta: {
    title: 'de refurbished (super)markt',
    description:
      'Koop refurbished smartphones, tablets en laptops op Back Market tot 70% goedkoper dan nieuw! Gratis en snelle verzending - Altijd 36 maanden garantie - Veilig betalen - 30 dagen recht op retour.',
    image: {
      src: 'https://images.ctfassets.net/mmeshd7gafk1/5BDjAiB7WVkFJcE5MXLNy4/916bdf410cefe591d4dbd2b7a655b12d/SEO-Website-Thumbnail.png',
      width: 516,
      height: 258,
      srcMobile: '',
      widthMobile: 0,
      heightMobile: 0,
      alt: '',
    },
    alternateLinks: [
      {
        country: 'FR',
        locale: 'fr-FR',
      },
      {
        country: 'DE',
        locale: 'de-DE',
      },
      {
        country: 'BE',
        locale: 'fr-BE',
      },
      {
        country: 'ES',
        locale: 'es-ES',
      },
      {
        country: 'IT',
        locale: 'it-IT',
      },
      {
        country: 'GB',
        locale: 'en-GB',
      },
      {
        country: 'AT',
        locale: 'de-AT',
      },
      {
        country: 'NL',
        locale: 'nl-NL',
      },
      {
        country: 'US',
        locale: 'en-US',
      },
      {
        country: 'FI',
        locale: 'fi-FI',
      },
      {
        country: 'PT',
        locale: 'pt-PT',
      },
      {
        country: 'IE',
        locale: 'en-IE',
      },
      {
        country: 'GR',
        locale: 'el-GR',
      },
      {
        country: 'SK',
        locale: 'sk-SK',
      },
      {
        country: 'SE',
        locale: 'sv-SE',
      },
      {
        country: 'JP',
        locale: 'ja-JP',
      },
      {
        country: 'AU',
        locale: 'en-AU',
      },
    ],
  },
  badge: '',
  titleSeo: 'de refurbished (super)markt',
  titleBreadcrumb: 'Home',
  banner: {},
  textSeo: null,
  hideFromSearchEngine: false,
  parentPageInfos: null,
  pageType: 'core',
  pageName: 'home-duplicated',
}

export default HOME_FALLBACK_DATA
